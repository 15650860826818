import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import {
  AboutTechSpecs,
  Carousel,
  CommonButton,
  ConfirmationDialog,
  DesignModal,
  ErrorMessage,
  InteractiveTutorial,
  Layout,
  ReviewsTable,
  SEO,
  SkeletonCarousel,
  SkeletonAboutTechSpecs,
  StoreSettingsModal,
} from 'components'
import { navigate } from 'gatsby'
import { updateToRefreshTrue } from 'actions/algoliaAction'
import { NOT_FOUND_ENDPOINT, USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import {
  BUTTON_STYLE,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import {
  DESIGN_PAGE,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import { markAsDeleted } from 'services/DesignService'
import { getModelByName } from 'services/GalleryService'
import {
  getStoreItemsByDesignId,
  updateMultipleStoreItems,
} from 'services/StoreService'
import { getThingiverseDesign } from 'services/ThingiverseService'

const useStyles = makeStyles(theme => ({
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: {
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    display: 'flex',
    fontSize: '0.8125rem',
    justifyContent: 'center',
    height: '34px',
    padding: '0px 3px',
    textAlign: 'center',
    verticalAlign: 'middle',
    [theme.breakpoints.between(788, 988)]: {
      fontSize: '0.78125rem',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  description: {
    marginLeft: '32px',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.up(1525)]: {
      marginLeft: '0px',
    },
  },
  modelPrivate: {
    display: 'flex',
    [theme.breakpoints.down(1025)]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down(600)]: {
      minWidth: '25%',
    },
  },
  techSpecs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    margin: '16px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      margin: '8px',
    },
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 'bold',
    padding: '20px 0px 0px 20px',
    lineHeight: '1.8',
    [theme.breakpoints.up(1525)]: {
      paddingLeft: 'calc(50% - 700px)',
    },
  },
  titleSkeleton: {
    padding: '20px 0px 0px 20px',
    marginBottom: '16px',
  },
  deleteContainer: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.background.red,
    },
  },
  actionContainer: {
    display: 'flex',
    '& .div': {
      backgroundColor: 'yellow',
    },
  },
  labelContainers: {
    marginLeft: '10px',
  },
  spacingBottom: {
    '& *': {
      marginBottom: '2px',
    },
  },
}))

const defaultModelState = {
  fetched: false,
  model: {
    images: [],
    is_public: true,
    is_support_required: false,
    name: '',
    ownedBy: {
      first_name: '',
      last_name: '',
      photo_url: '',
    },
    tech_specs: {
      depth: 0,
      height: 0,
      material: '',
      width: 0,
    },
    submission_date: {
      seconds: 0,
    },
  },
}

const DesignPage = ({ location }) => {
  const classes = useStyles()
  const { user: loggedUser, isLoggingOut: loggingOut } = useSelector(
    state => state.userState
  )
  const { state = {}, pathname } = location
  const [errorMessage, setErrorMessage] = useState('')
  const [modelState, setModelState] = useState(defaultModelState)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  useEffect(() => {
    const getModel = async () => {
      try {
        const formatted_name = pathname.split('/').pop()
        const response = await getModelByName(formatted_name)
        if (response.is_deleted) {
          navigate(NOT_FOUND_ENDPOINT)
        }
        setModelState({
          fetched: true,
          model: response,
        })
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    const getThingiverseModel = async () => {
      try {
        const design = await getThingiverseDesign(state.model.thingiverse_id)
        const images = design.data.images.map(thing => thing.sizes[12].url)
        setModelState({
          fetched: true,
          model: {
            ...state.model,
            id: state.model.id,
            images,
            submission_date: {
              seconds: state.model.submission_date._seconds,
            },
          },
        })
      } catch (error) {
        setErrorMessage('Something went wrong, please try again')
      }
    }

    if (state && state.model) {
      if (state.model.is_deleted) {
        navigate(NOT_FOUND_ENDPOINT)
      }
      if (state.model.thingiverse_id) {
        getThingiverseModel()
      } else {
        getModel()
      }
    } else {
      getModel()
    }
  }, [pathname, state])

  if (!modelState.model) {
    setModelState(defaultModelState)
    navigate(NOT_FOUND_ENDPOINT)
    return null
  }

  if (
    loggedUser &&
    modelState.model &&
    !modelState.model.is_public &&
    modelState.model.ownedBy.uid !== loggedUser.uid
  ) {
    navigate(NOT_FOUND_ENDPOINT)
  }

  const { description, images } = modelState.model

  const deleteStoreItems = async modelId => {
    try {
      const storeItems = await getStoreItemsByDesignId(modelId)
      if (storeItems.length > 0) {
        const storeItemIds = storeItems.map(storeItem => storeItem.uid)
        const fieldsToUpdate = {
          is_deleted: true,
          is_disabled: true,
        }
        await updateMultipleStoreItems(storeItemIds, fieldsToUpdate)
      }
    } catch (error) {
      throw error
    }
  }

  const handleDelete = async () => {
    try {
      const modelId = modelState.model.id
        ? modelState.model.id
        : modelState.model.uid
      await deleteStoreItems(modelId)
      await markAsDeleted(modelId)
      updateToRefreshTrue()
      navigate(USER_PROFILE_ENDPOINT(modelState.model.ownedBy.uid))
    } catch (error) {
      setErrorMessage(
        'An error occurred while attempting to delete the model, please try again later'
      )
    }
  }

  const openConfirmDialog = () => {
    setOpenDeleteDialog(true)
  }
  const handleCancelDeletion = () => {
    setOpenDeleteDialog(false)
  }

  const userLoggedState = () => {
    return loggedUser && !loggingOut && !loggedUser.isAnonymous
  }

  const isDesingOwner =
    loggedUser && loggedUser.uid === modelState.model.ownedBy.uid

  const isDesingOwnerAndPrivateModel =
    isDesingOwner && !modelState.model.is_public

  return (
    <Layout className={classes.alignCenter}>
      <SEO title="Model" />
      <ErrorMessage message={errorMessage} />
      {modelState.fetched ? (
        <div className={classes.content}>
          <Grid container spacing={4}>
            <Grid id="design-images" item lg={8} sm={7} xs={12}>
              <Carousel slides={images} thumbnails={true} />
              <div className={classes.description}>{description}</div>
            </Grid>
            <Grid
              container
              item
              lg={4}
              sm={5}
              xs={12}
              className={classes.techSpecs}
            >
              <div>
                <Grid
                  className={clsx(classes.center, classes.spacingBottom)}
                  container
                  item
                  xs={12}
                >
                  {isDesingOwnerAndPrivateModel && (
                    <Grid
                      className={clsx(
                        classes.alignCenter,
                        classes.modelPrivate
                      )}
                      container
                      item
                      xs={3}
                    >
                      <Typography className={classes.badge} variant="body1">
                        Private Model
                      </Typography>
                    </Grid>
                  )}
                  {isDesingOwner && (
                    <>
                      <Grid
                        className={classes.alignCenter}
                        container
                        direction="column"
                        item
                        xs={3}
                      >
                        <StoreSettingsModal
                          design={modelState.model}
                          loggedUser={loggedUser}
                        />
                      </Grid>
                      <Grid
                        className={classes.alignCenter}
                        container
                        direction="column"
                        item
                        xs={3}
                      >
                        <DesignModal
                          prevDesign={modelState.model}
                          editDesign={true}
                        />
                      </Grid>
                      <Grid
                        className={clsx(
                          classes.deleteContainer,
                          classes.alignCenter
                        )}
                        container
                        direction="column"
                        item
                        xs={3}
                      >
                        <CommonButton
                          buttonStyle={BUTTON_STYLE.PRIMARY}
                          fullWidth={false}
                          label="Delete"
                          size={BUTTON_SIZE.SMALL}
                          variant={BUTTON_VARIANT.OUTLINED}
                          onClick={openConfirmDialog}
                        />
                        <ConfirmationDialog
                          cancelLabel="No"
                          confirmLabel="Yes"
                          open={openDeleteDialog}
                          title="Are you sure you want to delete this design?"
                          onAccept={handleDelete}
                          onCancel={handleCancelDeletion}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <AboutTechSpecs model={modelState.model} />
              </div>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <ReviewsTable
                designId={
                  modelState.model.id
                    ? modelState.model.id
                    : modelState.model.uid
                }
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.content}>
          <Grid container spacing={4}>
            <Grid item lg={8} sm={7} xs={12}>
              <SkeletonCarousel />
              <Skeleton
                animation="wave"
                height={100}
                variant="rect"
                width="100%"
              />
            </Grid>
            <Grid item lg={4} sm={5} xs={12} className={classes.techSpecs}>
              <div>
                <div className={classes.titleSkeleton}>
                  <Skeleton
                    animation="wave"
                    height={40}
                    variant="rect"
                    width="30%"
                  />
                </div>
                <SkeletonAboutTechSpecs />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {userLoggedState() &&
        modelState.fetched &&
        loggedUser.tutorial_state &&
        loggedUser.tutorial_state.pageHeader &&
        !loggedUser.tutorial_state.designPage && (
          <InteractiveTutorial
            showTutorial={!loggedUser.tutorial_state.designPage}
            steps={DESIGN_PAGE}
            tutorial={TUTORIAL_NAMES.DESIGN_PAGE}
          />
        )}
    </Layout>
  )
}

DesignPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default DesignPage
