import getFirebase from './firebase'
import { MODELS_PER_PAGE_LIMIT } from 'constants/modelConstants'

import { ALL_CATEGORIES } from 'constants/catalogConstants'

const firebaseApp = getFirebase()

const getModels = () => firebaseApp.firestore().collection('models')

let lastVisible
let lastVisibleWithFilters

const queryAllModels = () =>
  getModels()
    .orderBy('submission_date', 'desc')
    .where('is_deleted', '==', false)
    .where('is_public', '==', true)
    .limit(MODELS_PER_PAGE_LIMIT)

const queryByCategory = category =>
  getModels()
    .where('category', '==', category)
    .where('is_deleted', '==', false)
    .where('is_public', '==', true)
    .orderBy('submission_date', 'desc')
    .limit(MODELS_PER_PAGE_LIMIT)

const queryByKeywords = keywords =>
  getModels()
    .where('keywords', 'array-contains-any', keywords)
    .where('is_deleted', '==', false)
    .where('is_public', '==', true)
    .orderBy('submission_date', 'desc')
    .limit(MODELS_PER_PAGE_LIMIT)

const queryByKeywordsAndCategory = (category, keywords) =>
  getModels()
    .where('category', '==', category)
    .where('is_deleted', '==', false)
    .where('is_public', '==', true)
    .where('keywords', 'array-contains-any', keywords)
    .orderBy('submission_date', 'desc')
    .limit(MODELS_PER_PAGE_LIMIT)

export const fetchModels = () => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      queryAllModels()
        .get()
        .then(item => {
          lastVisible = item.docs[item.docs.length - 1]
          resolve(
            item.docs.map(doc => ({
              uid: doc.id,
              ...doc.data(),
            }))
          )
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const fetchMoreModels = () => {
  return new Promise((resolve, reject) => {
    if (firebaseApp && lastVisible) {
      queryAllModels()
        .startAfter(lastVisible)
        .get()
        .then(item => {
          lastVisible = item.docs[item.docs.length - 1]
          resolve(
            item.docs.map(doc => ({
              uid: doc.id,
              ...doc.data(),
            }))
          )
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const searchWithFilter = ({
  category = ALL_CATEGORIES,
  searchInput = [],
}) => {
  return new Promise((resolve, reject) => {
    const searchInputLength = searchInput.length
    if (firebaseApp) {
      if (searchInputLength > 0 && category !== ALL_CATEGORIES) {
        queryByKeywordsAndCategory(category, searchInput)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            resolve(
              querySnapshot.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
              }))
            )
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      } else if (searchInputLength > 0 && category === ALL_CATEGORIES) {
        queryByKeywords(searchInput)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            const response = querySnapshot.docs.map(doc => ({
              uid: doc.id,
              ...doc.data(),
            }))
            resolve(response)
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      } else {
        queryByCategory(category)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            resolve(
              querySnapshot.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
              }))
            )
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      }
    }
  })
}

export const fetchMoreModelsWithFilter = (
  category = ALL_CATEGORIES,
  searchInput = []
) => {
  return new Promise((resolve, reject) => {
    const searchInputLength = searchInput.length
    if (firebaseApp) {
      if (searchInputLength > 0 && category !== ALL_CATEGORIES) {
        queryByKeywordsAndCategory(category, searchInput)
          .startAfter(lastVisibleWithFilters)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            resolve(
              querySnapshot.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
              }))
            )
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      } else if (searchInputLength > 0 && category === ALL_CATEGORIES) {
        queryByKeywords(searchInput)
          .startAfter(lastVisibleWithFilters)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            const response = querySnapshot.docs.map(doc => ({
              uid: doc.id,
              ...doc.data(),
            }))
            resolve(response)
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      } else {
        queryByCategory(category)
          .startAfter(lastVisibleWithFilters)
          .get()
          .then(querySnapshot => {
            lastVisibleWithFilters =
              querySnapshot.docs[querySnapshot.docs.length - 1]
            resolve(
              querySnapshot.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
              }))
            )
          })
          .catch(error => {
            console.log(error.message)
            reject(error)
          })
      }
    }
  })
}

export const getModelByName = formatted_name => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      getModels()
        .where('formatted_name', '==', formatted_name)
        .get()
        .then(snapshot => {
          const response = snapshot.docs.map(doc => ({
            uid: doc.id,
            id: doc.id,
            ...doc.data(),
          }))
          resolve(response[0])
        })
        .catch(error => {
          console.log(error.message)
          reject(error.message)
        })
    }
  })
}
